import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { App as AntdApp, ConfigProvider, Layout } from 'antd';
import { Content } from 'antd/es/layout/layout';

import LeftBar from 'components/LeftBar';
import Dashboard from 'pages/Dashboard/Dashboard';
import { useAppSelector } from 'store';

import styles from './App.module.scss';

// Create a client
const queryClient = new QueryClient();

const App = () => {
    const currentTheme = useAppSelector((state) => state.theme.algorithm);

    return (
        <QueryClientProvider client={queryClient}>
            <ConfigProvider
                theme={{
                    algorithm: currentTheme,
                    token: {
                        fontFamily: 'Source Sans Pro',
                        colorPrimary: '#c33595',
                    },
                }}
            >
                <AntdApp style={{ height: '100%' }}>
                    <Layout className={styles.mainLayout}>
                        <Layout>
                            <BrowserRouter>
                                <LeftBar />
                                <Content className={styles.content}>
                                    <Routes>
                                        <Route path='/' element={<Dashboard />} />
                                    </Routes>
                                </Content>
                            </BrowserRouter>
                        </Layout>
                    </Layout>
                </AntdApp>
            </ConfigProvider>
        </QueryClientProvider>
    );
};

export default App;

import endpoints from 'api/utils/endpoints';
import StrategyDefinition from 'components/strategies/StrategyDefinition';

export const getStrategyDefinition = async (id: string) => {
    const response = await endpoints.strategies.byId.get({
        templateValues: {
            strategyId: id,
        },
    });

    if (response.status !== 200) {
        throw new Error('Failed to load strategy definition');
    }

    return response.data as StrategyDefinition;
};

import { createSlice } from '@reduxjs/toolkit';
import { theme } from 'antd';

import { RootState } from './index';

const THEME_KEY = 'userTheme';

// Attempt to load the theme from localStorage or default to darkTheme
const savedTheme = localStorage.getItem(THEME_KEY);
const initialTheme = savedTheme === 'light' ? theme.defaultAlgorithm : theme.darkAlgorithm;

const initialState = {
    algorithm: initialTheme,
};

export const themeSlice = createSlice({
    name: 'theme',
    initialState,
    reducers: {
        enableLightAlgorithm: (state) => {
            state.algorithm = theme.defaultAlgorithm;
            localStorage.setItem(THEME_KEY, 'light');
        },
        enableDarkAlgorithm: (state) => {
            state.algorithm = theme.darkAlgorithm;
            localStorage.setItem(THEME_KEY, 'dark');
        },
    },
});
export const { enableLightAlgorithm, enableDarkAlgorithm } = themeSlice.actions;

export const selectCurrentAlgorithm = (state: RootState) => state.theme.algorithm;
export const selectCurrentTheme = (state: RootState) =>
    state.theme.algorithm === theme.darkAlgorithm ? 'dark' : 'light';

export default themeSlice.reducer;

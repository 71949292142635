import React, { Key, useState } from 'react';
import { Table } from 'antd';
import { FilterValue, SorterResult } from 'antd/es/table/interface';

import { MT5Account } from 'types/MT5Account';
import useAccountsTableColumns from './useAccountsTableColumns';

interface AccountsTableProps {
    accounts: MT5Account[];
}

const sorterStorageKey = 'dashboard-accounts-table-sorts';
const filterStorageKey = 'dashboard-accounts-table-filters';

const getLocalStorageValues = () => {
    const storedFilters = localStorage.getItem(filterStorageKey);
    const storedSorts = localStorage.getItem(sorterStorageKey);
    const output = {
        savedSortedInfo: null,
        savedFilteredInfo: null,
    };
    if (storedFilters) {
        output.savedFilteredInfo = JSON.parse(storedFilters);
    }
    if (storedSorts) {
        output.savedSortedInfo = JSON.parse(storedSorts);
    }

    return output;
};

const AccountsTable: React.FC<AccountsTableProps> = ({ accounts }) => {
    const { savedSortedInfo, savedFilteredInfo } = getLocalStorageValues();
    const [filteredInfo, setFilteredInfo] = useState<Record<string, FilterValue | null> | null>(
        savedFilteredInfo
    );
    const [sortedInfo, setSortedInfo] = useState<SorterResult<MT5Account> | null>(
        savedSortedInfo || { order: 'descend', columnKey: 'position' }
    );

    const columns = useAccountsTableColumns(accounts);

    const handleChange = (
        filters: Record<string, FilterValue | null>,
        sorter: SorterResult<MT5Account> | SorterResult<MT5Account>[]
    ) => {
        setFilteredInfo(filters);
        setSortedInfo(sorter as SorterResult<MT5Account>);
        localStorage.setItem(filterStorageKey, JSON.stringify(filters));
        if (!(sorter as SorterResult<MT5Account>).column) {
            localStorage.removeItem(sorterStorageKey);
        } else {
            localStorage.setItem(sorterStorageKey, JSON.stringify(sorter));
        }
    };

    if (sortedInfo) {
        const sortedColumn = columns.find((column) => column.key === sortedInfo.columnKey);

        if (sortedColumn) {
            sortedColumn.sortOrder = sortedInfo.order;
        }
    }

    if (filteredInfo) {
        columns.forEach((column) => {
            column.filteredValue = filteredInfo[column.key as Key];
        });
    }

    return (
        <Table
            dataSource={accounts}
            columns={columns}
            rowKey='accountId'
            pagination={false}
            onChange={(_, filters, sorter) => {
                handleChange(filters, sorter);
            }}
            scroll={{ y: 'calc(100vh - 90px - 76px)' }}
        />
    );
};

export default AccountsTable;

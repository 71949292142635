import { useEffect, useState } from 'react';
import { Button, Input, Typography } from 'antd';

const FunctionKeyInput = () => {
    const [inputKey, setInputKey] = useState(() => localStorage.getItem('functionKey') || '');

    const handleSaveKey = () => {
        localStorage.setItem('functionKey', inputKey);
        window.location.reload();
    };

    useEffect(() => {
        const storedKey = localStorage.getItem('functionKey');
        if (storedKey) setInputKey(storedKey);
    }, []);

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                height: '100%',
                boxSizing: 'border-box',
                padding: '20px',
            }}
        >
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Typography.Title level={2} style={{ marginBottom: '20px' }}>
                    Enter Function Key
                </Typography.Title>
                <Typography.Text style={{ marginBottom: '10px' }}>
                    Please enter your function key below to access the dashboard.
                </Typography.Text>
                <Input.TextArea
                    placeholder='Enter your function key'
                    value={inputKey}
                    onChange={(e) => setInputKey(e.target.value)}
                    style={{ width: '100%', marginBottom: '12px', resize: 'none' }}
                    onPressEnter={handleSaveKey}
                />
                <Button style={{ marginLeft: 'auto' }} type='primary' onClick={handleSaveKey}>
                    Save Key
                </Button>
            </div>
        </div>
    );
};

export default FunctionKeyInput;

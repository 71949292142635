import { Typography } from 'antd';

import { MT5Account } from 'types/MT5Account';

const AccountCardDescription = ({ account }: { account: MT5Account }) => (
    <>
        <Typography.Text type='secondary'>{account.propFirm}</Typography.Text>
        <br />
        <Typography.Text type='secondary'>{account.ea}</Typography.Text>
    </>
);

export default AccountCardDescription;

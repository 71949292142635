import { Divider, Typography } from 'antd';

import { MT5Account } from 'types/MT5Account';

const AccountCardBalance = ({ account }: { account: MT5Account }) => (
    <div
        style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        }}
    >
        <Typography.Text>Equity</Typography.Text>
        <Typography.Title
            level={3}
            style={{
                margin: 0,
                color: account.equity >= account.balance ? '#0f0' : 'red',
            }}
        >
            {account.equity}
        </Typography.Title>
        <Divider
            style={{
                margin: 0,
                borderWidth: 1,
                borderColor: '#bbb',
                width: '124%',
            }}
        />
        <Typography.Title
            level={3}
            style={{
                margin: 0,
                color: account.balance >= account.startingBalance ? '#0f0' : 'red',
            }}
        >
            {account.balance}
        </Typography.Title>
        <Typography.Text>Balance</Typography.Text>
    </div>
);

export default AccountCardBalance;

import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    DashboardOutlined,
    FileTextOutlined,
    LineChartOutlined,
    LogoutOutlined,
    RocketOutlined,
    SettingOutlined,
} from '@ant-design/icons';
import { useAuth0 } from '@auth0/auth0-react';
import { Col, Divider, Menu, MenuProps } from 'antd';
import Sider from 'antd/es/layout/Sider';

import logo from 'assets/signal-city-trading-logo.png';
import { config } from 'config';
import useTheme from 'hooks/useTheme';
import ThemeSwitch from './ThemeSwitch';
import { UserAvatar } from './UserAvatar';

type MenuItem = Required<MenuProps>['items'][number];

function getKeyFromPath(path: string) {
    switch (path) {
        case '/chart':
            return 'Chart';
        case '/strategies':
            return 'Strategies';
        case '/tradingview/stats':
            return 'TradingViewViewer';
        default:
            return 'Dashboard';
    }
}

const LeftBar = () => {
    const theme = useTheme();
    const navigate = useNavigate();

    const path = window.location.pathname;
    const [selectedTab, setSelectedTab] = useState([getKeyFromPath(path)]);

    const menuItems: MenuItem[] = useMemo(
        () => [
            {
                key: 'Dashboard',
                icon: <DashboardOutlined />,
                title: 'Dashboard',
                onClick: () => navigate('/'),
            },
        ],
        [navigate]
    );

    return (
        <Sider
            collapsedWidth={48}
            collapsed={true}
            collapsible={false}
            style={{
                background: theme.colorBgContainer,
                borderInlineEnd: '1px solid',
                borderInlineEndColor: theme.colorBorderSecondary,
            }}
        >
            <Col style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                <img
                    style={{
                        width: 72,
                        zIndex: 5,
                        marginLeft: 12,
                        marginTop: 12,
                    }}
                    src={logo}
                    alt='signal-city-trading-logo'
                />

                <Divider style={{ marginTop: '12px', marginBottom: '12px' }} />
                <Menu
                    style={{ border: 0 }}
                    selectedKeys={selectedTab}
                    expandIcon={true}
                    items={menuItems}
                    onClick={(e) => setSelectedTab([e.key])}
                />
                <div style={{ marginTop: 'auto', padding: 8 }}>
                    <ThemeSwitch />
                </div>
            </Col>
        </Sider>
    );
};

export default LeftBar;

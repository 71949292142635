import { createEndpoint } from './apiClientUtils';

const endpoints = {
    user: {
        settings: createEndpoint('/user/settings', ['get', 'put']),
    },
    candles: {
        list: createEndpoint('/candles', ['get']),
        stream: createEndpoint('/candles/stream', ['get']),
    },
    strategies: {
        list: createEndpoint('/strategy', ['get']),
        run: createEndpoint('/strategy/{strategyId}/run', ['post']),
        save: createEndpoint('/strategy', ['post']),
        byId: createEndpoint('/strategy/{strategyId}', ['get', 'delete']),
        favorite: createEndpoint('/strategy/favorite/{strategyId}', ['put', 'delete']),
        rename: createEndpoint('/strategy/{strategyId}/name', ['put']),
    },
    indicators: {
        list: createEndpoint('/indicators', ['get']),
        run: createEndpoint('/indicators/{indicatorId}', ['get']),
        settings: createEndpoint('/indicators/settings', ['get']),
        favorites: createEndpoint('/indicators/favorites', ['get']),
        favorite: createEndpoint('/indicators/favorite/{indicatorId}', ['put', 'delete']),
    },
    charts: {
        list: createEndpoint('/charts', ['get']), // GET the user's charts
    },
};

export default endpoints;

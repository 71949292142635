import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import endpoints from 'api/utils/endpoints';
import { RootState } from 'store';

interface IUserSettings {
    theme?: 'light' | 'dark';
    strategyStatisticFilters?: string[];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    strategyResultTable?: any;
}

const initialState: {
    state: 'idle' | 'loading' | 'loaded' | 'failed';
    settings: IUserSettings;
} = {
    state: 'idle',
    settings: {},
};

export const loadUserSettings = createAsyncThunk('userSettings/loadUserSettings', async () => {
    const response = await endpoints.user.settings.get();
    if (response.status !== 200) throw new Error('Failed to load user settings');
    return response.data;
});

export const saveUserSettings = createAsyncThunk(
    'userSettings/saveUserSettings',
    async (settings: IUserSettings) => {
        const response = await endpoints.user.settings.put({
            data: settings,
        });
        if (response.status !== 200) throw new Error('Failed to save user settings');
        return response.data;
    }
);

const userSettingsSlice = createSlice({
    name: 'userSettings',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(loadUserSettings.fulfilled, (state, action) => {
            state.state = 'loaded';
            state.settings = action.payload;
        });
    },
});

export const selectStrategyStatisticFilter = (state: RootState) =>
    state.userSettings.settings.strategyStatisticFilters;

export default userSettingsSlice.reducer;

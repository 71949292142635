import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { Action, combineReducers, configureStore, ThunkAction } from '@reduxjs/toolkit';
import { enableMapSet } from 'immer';

import chartsSlice from './chartsSlice';
import indicatorSlice from './indicatorSlice';
import strategySlice from './strategySlice';
import themeSlice from './themeSlice';
import userSettingsSlice from './userSettingsSlice';
import utilitySlice from './utilitySlice';

enableMapSet();

const rootReducer = combineReducers({
    theme: themeSlice,
    charts: chartsSlice,
    indicators: indicatorSlice,
    strategies: strategySlice,
    utility: utilitySlice,
    userSettings: userSettingsSlice,
});

const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
    devTools: process.env.NODE_ENV !== 'production', // Enable Redux DevTools only in development
});

export default store;

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;

// Use throughout your app instead of plain `useDispatch` and `useSelector`

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

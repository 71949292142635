import {
    CloseCircleOutlined,
    DisconnectOutlined,
    DownCircleOutlined,
    UpCircleOutlined,
} from '@ant-design/icons';

import { MT5Account } from 'types/MT5Account';
import { isAccountDisconnected } from 'utils/MT5AccountTools';

const AccountCardStatusIcon = ({ account }: { account: MT5Account }) => {
    const isDisconnected = isAccountDisconnected(account);

    if (isDisconnected) {
        return <DisconnectOutlined style={{ color: 'orange', fontSize: '3em' }} />;
    }

    return !account.eaActive ? (
        <CloseCircleOutlined style={{ color: 'grey', fontSize: '3em' }} />
    ) : account.equity > account.startingBalance ? (
        <UpCircleOutlined style={{ color: '#00ff00', fontSize: '3em' }} />
    ) : (
        <DownCircleOutlined style={{ color: '#ff0000', fontSize: '3em' }} />
    );
};

export default AccountCardStatusIcon;

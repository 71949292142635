import { CheckOutlined, DisconnectOutlined, PauseOutlined } from '@ant-design/icons';
import { DescriptionOutlined } from '@mui/icons-material';
import { Tag, Tooltip } from 'antd';
import currency from 'currency.js';
import { upperFirst } from 'lodash';

import { MT5Account } from 'types/MT5Account';
import { AccountStatus, getAccountStatus } from 'utils/MT5AccountTools';

export const accountStatusSorter = (a: MT5Account, b: MT5Account) => {
    const accountStatusCompareValues: Record<AccountStatus, number> = {
        active: 1,
        paused: 2,
        disconnected: 3,
    };
    const accountStatusA = getAccountStatus(a);
    const accountStatusB = getAccountStatus(b);
    return accountStatusCompareValues[accountStatusA] - accountStatusCompareValues[accountStatusB];
};

export const accountIdSorter = (a: MT5Account, b: MT5Account) =>
    parseInt(a.accountId) - parseInt(b.accountId);

export const nameSorter = (a: MT5Account, b: MT5Account) => {
    if (a.name && b.name) {
        return a.name.localeCompare(b.name);
    }
    return 0;
};

export const positionSorter = (a: MT5Account, b: MT5Account) => {
    if (!a.target || !a.maxDrawdown || !b.target || !b.maxDrawdown) {
        return 0;
    }
    const aValue =
        Math.max(a.equity, a.balance) > 0
            ? Math.max(a.equity, a.balance)
            : Math.min(a.equity, a.balance);
    const bValue =
        Math.max(b.equity, b.balance) > 0
            ? Math.max(b.equity, b.balance)
            : Math.min(b.equity, b.balance);
    const aPositionPercentage = ((aValue - a.maxDrawdown) / (a.target - a.maxDrawdown)) * 200 - 100;
    const bPositionPercentage = ((bValue - b.maxDrawdown) / (b.target - b.maxDrawdown)) * 200 - 100;
    return aPositionPercentage - bPositionPercentage;
};

export const targetSorter = (a: MT5Account, b: MT5Account) => a.target - b.target;

export const maxDrawdownSorter = (a: MT5Account, b: MT5Account) => a.maxDrawdown - b.maxDrawdown;

export const propFirmSorter = (a: MT5Account, b: MT5Account) =>
    a.propFirm.localeCompare(b.propFirm);

export const eaSorter = (a: MT5Account, b: MT5Account) => a.ea.localeCompare(b.ea);

export const tradesSorter = (a: MT5Account, b: MT5Account) =>
    a.openTrades.length - b.openTrades.length;

export const descriptionSorter = (a: MT5Account, b: MT5Account) => {
    if (!a.description && b.description) {
        return 1;
    } else if (a.description && !b.description) {
        return -1;
    }
    return 0;
};

export const statusRenderer = (_: string, account: MT5Account) => {
    const accountStatus = getAccountStatus(account);
    const tooltipText = upperFirst(accountStatus);
    let color = 'yellow';
    let icon = <PauseOutlined />;

    if (accountStatus === 'disconnected') {
        color = 'red';
        icon = <DisconnectOutlined />;
    } else if (accountStatus === 'active') {
        color = 'green';
        icon = <CheckOutlined />;
    }

    return (
        <Tooltip title={tooltipText}>
            <Tag color={color}>{icon}</Tag>
        </Tooltip>
    );
};

export const positionRenderer = (_: string, record: MT5Account) => {
    if (!record.target || !record.maxDrawdown) {
        return '-';
    }

    const value = record.equity;
    let positionPercentage = 0;
    let color = 'white';

    if (value < record.startingBalance) {
        color = '#f00';
        // Calculate percentage to maxDrawdown
        positionPercentage =
            (record.startingBalance - value) / (record.startingBalance - record.maxDrawdown);
    } else if (value > record.startingBalance) {
        color = '#0f0';
        // Calculate percentage to target
        positionPercentage =
            (value - record.startingBalance) / (record.target - record.startingBalance);
    }

    return <span style={{ color: color }}>{Math.abs(positionPercentage * 100).toFixed(2)}%</span>;
};

export const targetRenderer = (target: number) =>
    target === 0 || target === undefined ? '-' : currency(target, { precision: 0 }).format();

export const maxDrawdownRenderer = (maxDrawdown: number) =>
    maxDrawdown === 0 || maxDrawdown === undefined
        ? '-'
        : currency(maxDrawdown, { precision: 0 }).format();

export const descriptionRenderer = (description: string) => {
    if (description) {
        return (
            <Tooltip title={description}>
                <DescriptionOutlined />
            </Tooltip>
        );
    }
    return null;
};

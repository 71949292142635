import { Typography } from 'antd';

import { MT5Account } from 'types/MT5Account';

const AccountCartTitle = ({ account }: { account: MT5Account }) => (
    <>
        <Typography.Text>{account.name ? `${account.name}` : ''}</Typography.Text>
        <Typography.Text strong>{`\n${account.accountId}`}</Typography.Text>
    </>
);

export default AccountCartTitle;

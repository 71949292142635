import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { DefaultOptionType } from 'antd/es/select';

import endpoints from 'api/utils/endpoints';
import { RootState } from 'store';
import { IndicatorConfig } from 'types/indicators/IndicatorConfig';

interface IIndicatorState {
    loaded: boolean;
    indicators: IndicatorConfig[];
    indicatorOptions: DefaultOptionType[];
    favorites: string[];
}

const initialState: IIndicatorState = {
    loaded: false,
    indicators: [],
    indicatorOptions: [],
    favorites: [],
};

export const loadIndicators = createAsyncThunk('indicators/load', async () => {
    const response = await endpoints.indicators.list.get();

    if (response.status !== 200) {
        throw new Error('Failed to load indicators');
    }

    return response.data as IndicatorConfig[];
});

export const loadFavorites = createAsyncThunk('indicators/loadFavorites', async () => {
    const response = await endpoints.indicators.favorites.get();

    if (response.status !== 200) {
        throw new Error('Failed to load favorites');
    }

    return response.data as string[];
});

export const addFavorite = createAsyncThunk('indicators/addFavorite', async (indicator: string) => {
    const response = await endpoints.indicators.favorite.put({
        templateValues: {
            indicatorId: indicator,
        },
    });

    if (response.status !== 200) {
        throw new Error('Failed to add favorite');
    }

    return indicator;
});

export const removeFavorite = createAsyncThunk(
    'indicators/removeFavorite',
    async (indicator: string) => {
        const response = await endpoints.indicators.favorite.delete({
            templateValues: {
                indicatorId: indicator,
            },
        });

        if (response.status !== 200) {
            throw new Error('Failed to remove favorite');
        }

        return indicator;
    }
);

const indicatorSlice = createSlice({
    name: 'indicators',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(loadIndicators.fulfilled, (state, action) => {
            state.indicators = action.payload;
            state.indicatorOptions = action.payload.map((indicator: IndicatorConfig) => ({
                label: indicator.name,
                value: indicator.id,
            }));
            state.loaded = loadFavorites.fulfilled.match(action) && state.loaded;
        });

        builder.addCase(loadFavorites.fulfilled, (state, action) => {
            state.favorites = action.payload;
            state.loaded = loadIndicators.fulfilled.match(action) && state.loaded;
        });

        builder.addCase(addFavorite.pending, (state, action) => {
            state.favorites.push(action.meta.arg);
        });

        builder.addCase(addFavorite.fulfilled, (state, action) => {
            state.favorites.push(action.payload);
        });

        builder.addCase(addFavorite.rejected, (state, action) => {
            state.favorites = state.favorites.filter((f) => f !== action.meta.arg);
        });

        builder.addCase(removeFavorite.pending, (state, action) => {
            state.favorites = state.favorites.filter((f) => f !== action.meta.arg);
        });

        builder.addCase(removeFavorite.fulfilled, (state, action) => {
            state.favorites = state.favorites.filter((f) => f !== action.payload);
        });

        builder.addCase(removeFavorite.rejected, (state, action) => {
            state.favorites.push(action.meta.arg);
        });
    },
});

export const selectIndicators = (state: RootState) => state.indicators.indicators;
export const selectIndicatorOptions = (state: RootState) => state.indicators.indicatorOptions;
export const selectIndicatorsLoaded = (state: RootState) => state.indicators.loaded;
export const selectFavorites = (state: RootState) => state.indicators.favorites;

export default indicatorSlice.reducer;

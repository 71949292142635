import { Key } from 'react';
import { Popover, Statistic } from 'antd';
import { ColumnType } from 'antd/es/table';
import { uniq } from 'lodash';
import _ from 'lodash';

import { MT5Account } from 'types/MT5Account';
import {
    accountIdSorter,
    accountStatusSorter,
    descriptionRenderer,
    descriptionSorter,
    eaSorter,
    maxDrawdownRenderer,
    maxDrawdownSorter,
    nameSorter,
    positionRenderer,
    positionSorter,
    propFirmSorter,
    statusRenderer,
    targetRenderer,
    targetSorter,
    tradesSorter,
} from './tableFunctions';

const useAccountsTableColumns = (accounts: MT5Account[]): ColumnType<MT5Account>[] => {
    const challengeSizes = _.chain(accounts)
        .map((account) => account.startingBalance)
        .uniq()
        .map((size) => ({
            text: size / 1000 + 'K',
            value: size,
        }))
        .orderBy(['value'], ['asc'])
        .value();

    return [
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            width: 72,
            sorter: accountStatusSorter,
            render: statusRenderer,
        },
        {
            title: 'ID',
            dataIndex: 'accountId',
            key: 'accountId',
            width: 100,
            sorter: accountIdSorter,
        },
        {
            title: 'Challenge Size',
            dataIndex: 'startingBalance',
            key: 'challengeSize',
            width: 100,
            ellipsis: true,
            sorter: (a: MT5Account, b: MT5Account) => {
                return a.startingBalance > b.startingBalance ? 1 : -1;
            },
            render: (value: number) => {
                return value / 1000 + 'K';
            },
            filters: challengeSizes,
            onFilter: (value: boolean | Key, record: MT5Account) =>
                record.startingBalance === value,
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            ellipsis: true,
            width: 156,
            sorter: nameSorter,
        },
        {
            title: 'Balance / Equity',
            dataIndex: 'performance',
            key: 'performance',
            width: 128,
            ellipsis: true,
            render: (_: string, record: MT5Account) => {
                const balancePercentage =
                    ((record.balance - record.startingBalance) / record.startingBalance) * 100;
                const equityPercentage =
                    ((record.equity - record.startingBalance) / record.startingBalance) * 100;
                const balanceColor =
                    balancePercentage > 0 ? '#0f0' : balancePercentage < 0 ? '#f00' : 'white';
                const equityColor =
                    equityPercentage > 0 ? '#0f0' : equityPercentage < 0 ? '#f00' : 'white';

                const content = (
                    <div style={{ display: 'flex', gap: 16 }}>
                        <Statistic
                            title={
                                record.balance === record.equity ? 'Balance / Equity' : 'Balance'
                            }
                            value={record.balance}
                        />
                        {record.balance !== record.equity && (
                            <Statistic title='Equity' value={record.equity} />
                        )}
                    </div>
                );

                return (
                    <Popover content={content}>
                        <div style={{ display: 'flex', gap: 4 }}>
                            <span style={{ color: balanceColor }}>
                                {balancePercentage.toFixed(2)}%
                            </span>
                            {record.balance !== record.equity && (
                                <>
                                    <span style={{ color: '#888' }}>/</span>
                                    <span style={{ color: equityColor }}>
                                        {equityPercentage.toFixed(2)}%
                                    </span>
                                </>
                            )}
                        </div>
                    </Popover>
                );
            },
            sorter: (a: MT5Account, b: MT5Account) => {
                const aEquityPercentage =
                    ((a.equity - a.startingBalance) / a.startingBalance) * 100;
                const bEquityPercentage =
                    ((b.equity - b.startingBalance) / b.startingBalance) * 100;
                return aEquityPercentage - bEquityPercentage;
            },
        },
        {
            title: 'Relative Equity',
            dataIndex: 'equity',
            key: 'equityRelative',
            width: 128,
            ellipsis: true,
            render: (equity: number, record: MT5Account) => {
                const percentage = ((equity - record.balance) / record.balance) * 100;
                const color = percentage > 0 ? '#0f0' : percentage < 0 ? '#f00' : 'white';
                return <span style={{ color: color }}>{percentage.toFixed(2)}%</span>;
            },
            sorter: (a: MT5Account, b: MT5Account) => {
                return (
                    ((a.equity - a.balance) / a.balance) * 100 -
                    ((b.equity - b.balance) / b.balance) * 100
                );
            },
        },
        {
            title: 'Position',
            dataIndex: 'position',
            key: 'position',
            width: 82,
            render: positionRenderer,
            sorter: positionSorter,
        },
        {
            title: 'Target',
            dataIndex: 'target',
            key: 'target',
            width: 82,
            render: targetRenderer,
            sorter: targetSorter,
        },
        {
            title: 'Max DD',
            dataIndex: 'maxDrawdown',
            key: 'maxDrawdown',
            width: 82,
            render: maxDrawdownRenderer,
            sorter: maxDrawdownSorter,
        },
        {
            title: 'Prop Firm',
            dataIndex: 'propFirm',
            key: 'propFirm',
            ellipsis: true,
            width: 128,
            sorter: propFirmSorter,
            filters: uniq(accounts.map((account) => account.propFirm)).map((propFirm) => ({
                text: propFirm,
                value: propFirm,
            })),
            onFilter: (value: boolean | React.Key, record: MT5Account) => record.propFirm === value,
        },
        {
            title: 'EA',
            dataIndex: 'ea',
            key: 'ea',
            ellipsis: true,
            width: 128,
            sorter: eaSorter,
            filters: uniq(accounts.map((account) => account.ea)).map((ea) => ({
                text: ea,
                value: ea,
            })),
            onFilter: (value: boolean | React.Key, record: MT5Account) => record.ea === value,
        },
        {
            title: 'Trades',
            dataIndex: 'openTrades',
            key: 'trades',
            width: 100,
            sorter: tradesSorter,
            render: (_: string, record: MT5Account) => (
                <div style={{ display: 'flex', gap: '4px' }}>
                    {record.openTrades.map((trade, index) => (
                        <Popover
                            key={index}
                            content={
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        padding: '8px',
                                        gap: 16,
                                    }}
                                >
                                    <div
                                        style={{ display: 'flex', flexDirection: 'column', gap: 8 }}
                                    >
                                        <Statistic title='Symbol' value={trade.symbol} />
                                        <Statistic title='Type' value={trade.type} />
                                    </div>
                                    <div
                                        style={{ display: 'flex', flexDirection: 'column', gap: 8 }}
                                    >
                                        <Statistic title='Entry Price' value={trade.entryPrice} />
                                        <Statistic title='Volume' value={trade.volume} />
                                    </div>
                                    <div
                                        style={{ display: 'flex', flexDirection: 'column', gap: 8 }}
                                    >
                                        <Statistic title='Take Profit' value={trade.takeProfit} />
                                        <Statistic title='Stop Loss' value={trade.stopLoss} />
                                    </div>
                                </div>
                            }
                            trigger='hover'
                        >
                            <div
                                style={{
                                    width: '16px',
                                    height: '16px',
                                    backgroundColor: 'orange',
                                    border: '1px solid transparent',
                                }}
                                onMouseEnter={(e) =>
                                    (e.currentTarget.style.border = '1px solid white')
                                }
                                onMouseLeave={(e) =>
                                    (e.currentTarget.style.border = '1px solid transparent')
                                }
                            ></div>
                        </Popover>
                    ))}
                </div>
            ),
        },
        {
            title: 'Notes',
            dataIndex: 'description',
            key: 'description',
            width: 64,
            sorter: descriptionSorter,
            render: descriptionRenderer,
        },
    ];
};

export default useAccountsTableColumns;

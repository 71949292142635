import { useEffect, useState } from 'react';
import { ReloadOutlined, TableOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { useQuery } from '@tanstack/react-query';
import { Button, Layout, Radio, RadioChangeEvent, Typography } from 'antd';

import FullScreenSpinner from 'components/FullScreenSpinner';
import FunctionKeyInput from '../FunctionKeyInput';
import { getAccounts } from './AccountsApi';
import AccountsCards from './AccountsCards';
import AccountsTable from './AccountsTable';

const Dashboard = () => {
    const functionKey = localStorage.getItem('functionKey');
    const [viewMode, setViewMode] = useState<'table' | 'cards'>(
        (localStorage.getItem('dashboard-viewMode') as 'table' | 'cards') || 'table'
    );

    useEffect(() => {
        localStorage.setItem('dashboard-viewMode', viewMode);
    }, [viewMode]);

    const {
        data: accounts,
        isLoading: isAccountsLoading,
        error: accountsError,
        refetch,
    } = useQuery({
        retry: false,
        queryKey: ['accounts', functionKey],
        queryFn: () => getAccounts(functionKey),
    });

    useEffect(() => {
        const intervalId = setInterval(() => {
            refetch();
        }, 60 * 1000);

        return () => clearInterval(intervalId);
    }, [refetch]);

    const handleRefreshAccounts = () => {
        refetch();
    };

    const handleViewModeChange = (e: RadioChangeEvent) => {
        setViewMode(e.target.value);
    };

    if (isAccountsLoading && functionKey) {
        return <FullScreenSpinner />;
    }

    if (!functionKey || accountsError) {
        return <FunctionKeyInput />;
    }

    if (!accounts?.length) {
        return <div>Something went wrong</div>;
    }

    return (
        <Layout style={{ width: '100%' }}>
            <div style={{ display: 'flex', alignItems: 'center', gap: 16 }}>
                <Typography.Title style={{ marginLeft: 48 }} level={1}>
                    Accounts
                </Typography.Title>
                <Radio.Group
                    size='large'
                    style={{ marginLeft: 'auto' }}
                    onChange={handleViewModeChange}
                    value={viewMode}
                >
                    <Radio.Button value='table'>
                        <UnorderedListOutlined />
                    </Radio.Button>
                    <Radio.Button value='cards'>
                        <TableOutlined />
                    </Radio.Button>
                </Radio.Group>
                <div style={{ marginRight: 48 }}>
                    <Button
                        size='large'
                        onClick={handleRefreshAccounts}
                        icon={<ReloadOutlined />}
                    />
                </div>
            </div>

            {viewMode === 'cards' ? (
                <AccountsCards accounts={accounts} />
            ) : (
                <AccountsTable accounts={accounts} />
            )}
        </Layout>
    );
};

export default Dashboard;

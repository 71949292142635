import { Divider, Typography } from 'antd';

import { MT5Account } from 'types/MT5Account';
import AccountCardBalance from './AccountCardBalance';

const AccountCardContent = ({ account }: { account: MT5Account }) => (
    <div
        style={{
            marginTop: 24,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 8,
        }}
    >
        <Typography.Text
            style={{
                fontSize: '14px',
                color: 'darkred',
            }}
        >
            {account.maxDrawdown}
        </Typography.Text>
        <Divider
            type='vertical'
            style={{
                height: 24,
                marginLeft: 2,
                marginRight: 8,
                borderWidth: 1,
                borderColor: '#bbb',
            }}
        />
        <AccountCardBalance account={account} />
        <Divider
            type='vertical'
            style={{
                height: 24,
                marginLeft: 8,
                marginRight: 2,
                borderWidth: 1,
                borderColor: '#bbb',
            }}
        />
        <Typography.Text style={{ fontSize: '12px', color: 'green' }}>
            {account.target}
        </Typography.Text>
    </div>
);

export default AccountCardContent;

import { createAsyncThunk, createSlice, nanoid } from '@reduxjs/toolkit';

import chartRepo from 'repos/chartRepo';
import { getStrategyDefinition } from 'repos/strategyRepo';
import { RootState } from 'store';
import ChartConfig, { ChartIndicator } from 'types/charts/ChartConfig';

type IndicatorUpdateType = 'add' | 'remove' | 'update';

interface IChartsSlice {
    loaded: boolean;
    charts: ChartConfig[];
    dirtyCharts: Set<string>;
    dirtyIndicators: Record<string, IndicatorUpdateType>;
    favorites: string[];
    activeChart?: string;
    settingsModalIndicatorId: string | null;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    currentTimeRange: any | null;
}

const initialState: IChartsSlice = {
    loaded: true,
    dirtyCharts: new Set<string>(),
    dirtyIndicators: {},
    charts: [],
    favorites: [],
    activeChart: undefined,
    settingsModalIndicatorId: null,
    currentTimeRange: null,
};

const getActiveChart = (state: IChartsSlice) =>
    state.charts.find((chart) => chart.id === state.activeChart);

const getActiveStrategy = (state: IChartsSlice) => getActiveChart(state)?.strategy;

export const loadStrategyDefinition = createAsyncThunk(
    'charts/strategyDefinition',
    getStrategyDefinition
);

export const loadCharts = createAsyncThunk('charts/load', async () => {
    return await chartRepo.loadCharts();
});

export const saveChart = createAsyncThunk('charts/save', async (chart: ChartConfig) => {
    console.log('Saving chart', chart);
    return true;
});

const setChartDirty = (state: IChartsSlice, chartId: string | undefined) => {
    if (!chartId) return;
    if (!state.dirtyCharts.has(chartId)) return;
    state.dirtyCharts.add(chartId);
};

const chartsSlice = createSlice({
    name: 'charts',
    initialState,
    reducers: {
        updateChart: (state, action) => {
            const updatedFields = action.payload;
            if (!state.activeChart) return;
            const chartIndex = state.charts.findIndex((chart) => chart.id === state.activeChart);

            if (chartIndex !== -1) {
                state.charts[chartIndex] = { ...state.charts[chartIndex], ...updatedFields };
            }

            setChartDirty(state, state.activeChart);
        },
        setActiveChart: (state, action) => {
            state.activeChart = action.payload;
        },
        setIndicatorDirty: (state, { payload: { id, type } }) => {
            if (!state.activeChart) return;
            const chartIndex = state.charts.findIndex((chart) => chart.id === state.activeChart);

            if (chartIndex === -1) return;

            const indicatorIndex = state.charts[chartIndex].indicators.findIndex(
                (i) => i.id === id
            );

            if (indicatorIndex === -1) return;

            state.dirtyIndicators[id] = type;
        },
        unsetIndicatorDirty: (state, { payload: { id } }) => {
            delete state.dirtyIndicators[id];
        },
        addIndicator: (state, action) => {
            const activeChart = getActiveChart(state);
            if (!activeChart) return;
            const indicator: ChartIndicator = {
                id: nanoid(),
                visible: true,
                name: action.payload.name,
                baseIndicator: action.payload,
                styles: {}, // TODO: Set these from the base indicator default values.
                settings: {}, // TODO: Set these from the base indicator default values.
            };

            activeChart.indicators.push(indicator);

            setChartDirty(state, state.activeChart);
            state.dirtyIndicators[indicator.id] = 'add';
        },

        updateStrategy: (state, action) => {
            console.log('updating strategy');
            const activeStrategy = getActiveStrategy(state);
            if (!activeStrategy) return;
            activeStrategy.baseStrategy = { ...action.payload };
        },

        renameStrategy: (state, action) => {
            const activeStrategy = getActiveStrategy(state);
            if (!activeStrategy) return;
            activeStrategy.name = action.payload;
        },

        setStrategyVisible: (state, action) => {
            const activeStrategy = getActiveStrategy(state);
            if (!activeStrategy) return;
            activeStrategy.visible = action.payload;
        },

        removeStrategy: (state) => {
            const activeChart = getActiveChart(state);
            if (!activeChart) return;
            const activeStrategy = activeChart.strategy;
            if (!activeStrategy) return;
            activeChart.strategy = undefined;
        },

        updateIndicator: (state, { payload }) => {
            const activeChart = getActiveChart(state);
            if (!activeChart) return;
            const indicatorIndex = activeChart.indicators.findIndex((i) => i.id === payload.id);
            if (indicatorIndex === -1) return;
            activeChart.indicators[indicatorIndex] = payload;
            state.dirtyIndicators[payload.id] = 'update';
        },
        removeIndicator: (state, { payload }) => {
            const activeChart = getActiveChart(state);
            if (!activeChart) return;
            activeChart.indicators = activeChart.indicators.filter((i) => i.id !== payload);

            setChartDirty(state, state.activeChart);
            state.dirtyIndicators[payload] = 'remove';
        },
        addChart: (state, { payload }) => {
            const chart = payload as ChartConfig;
            state.charts.push(chart);

            setChartDirty(state, chart.id);
        },
        clearDirtyIndicators: (state) => {
            state.dirtyIndicators = {};
        },
        removeChart: (state, action) => {
            state.charts = state.charts.filter((i) => i.id !== action.payload);
        },
        addFavorite: (state, action) => {
            state.favorites.push(action.payload);
        },
        removeFavorite: (state, action) => {
            state.favorites = state.favorites.filter((f) => f !== action.payload);
        },
        setSettingsModalIndicatorId: (state, action) => {
            state.settingsModalIndicatorId = action.payload;
        },

        setIndicatorVisibility: (state, { payload: { id, visible } }) => {
            const activeChart = getActiveChart(state);
            if (!activeChart) return;
            const indicatorIndex = activeChart.indicators.findIndex((i) => i.id === id);
            if (indicatorIndex === -1) return;
            activeChart.indicators[indicatorIndex].visible = visible;

            setChartDirty(state, state.activeChart);
        },

        setCurrentTimeRange: (state, action) => {
            state.currentTimeRange = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(loadCharts.fulfilled, (state, action) => {
            state.charts = action.payload;
            state.loaded = true;
        });

        builder.addCase(saveChart.fulfilled, (state) => {
            state.dirtyCharts.clear();
        });

        builder.addCase(loadStrategyDefinition.fulfilled, (state, action) => {
            const activeChart = getActiveChart(state);
            if (!activeChart) return;
            activeChart.strategy = {
                id: nanoid(),
                name: action.payload.name,
                visible: true,
                baseStrategy: action.payload,
            };
        });
    },
});

export const {
    addFavorite,
    removeFavorite,
    addChart,
    removeChart,
    updateChart,
    updateStrategy,
    renameStrategy,
    removeStrategy,
    setStrategyVisible,
    setActiveChart,
    addIndicator,
    setSettingsModalIndicatorId,
    setIndicatorVisibility,
    updateIndicator,
    removeIndicator,
    setIndicatorDirty,
    unsetIndicatorDirty,
    clearDirtyIndicators,
    setCurrentTimeRange,
} = chartsSlice.actions;

export const selectCharts = (state: RootState) => state.charts.charts;
export const selectChartsLoaded = (state: RootState) => state.charts.loaded;
export const selectFavorites = (state: RootState) => state.charts.favorites;
export const selectActiveChart = (state: RootState) => getActiveChart(state.charts);
export const selectSettingsModalIndicatorId = (state: RootState) =>
    state.charts.settingsModalIndicatorId;
export const selectDirtyIndicators = (state: RootState) => state.charts.dirtyIndicators;

export const selectStrategyInputs = (state: RootState) => {
    const activeChart = state.charts.charts.find((i) => i.id === state.charts.activeChart);
    if (!activeChart) return;
    const strategy = activeChart.strategy;
    if (!strategy) return;
    return strategy.baseStrategy.inputs;
};

export const selectStrategySettings = (state: RootState) => {
    const activeChart = state.charts.charts.find((i) => i.id === state.charts.activeChart);
    if (!activeChart) return;
    const strategy = activeChart.strategy;
    if (!strategy) return;
    return strategy.baseStrategy.settings;
};

export const selectStrategy = (state: RootState) =>
    state.charts.charts.find((i) => i.id === state.charts.activeChart)?.strategy;

export const selectStrategyVisible = (state: RootState) =>
    state.charts.charts.find((i) => i.id === state.charts.activeChart)?.strategy?.visible;

export const selectCurrentTimeRange = (state: RootState) => state.charts.currentTimeRange;

export default chartsSlice.reducer;

import React, { useEffect, useMemo, useState } from 'react';
import { Card, Select } from 'antd';
import Meta from 'antd/es/card/Meta';
import { orderBy } from 'lodash';

import useTheme from 'hooks/useTheme';
import { MT5Account } from 'types/MT5Account';
import { isAccountDisconnected } from 'utils/MT5AccountTools';
import AccountCardDescription from './AccountCard/AccountCardDescription';
import AccountCardStatusIcon from './AccountCard/AccountCardStatusIcon';
import AccountCardTitle from './AccountCard/AccountCardTitle';
import AccountCardContent from './AccountCard/AccountCartContent';
import { FilterTypes, OrderByDirectionTypes, OrderByFieldTypes } from './FilterTypes';

interface AccountsCardsProps {
    accounts: MT5Account[];
}

const AccountsCards = ({ accounts }: AccountsCardsProps) => {
    const { colorBgContainer } = useTheme();

    const [filter, setFilter] = useState<FilterTypes>(
        (localStorage.getItem('dashboard-cards-filter') as FilterTypes) || 'all'
    );
    const [orderByField, setOrderByField] = useState<OrderByFieldTypes>(
        (localStorage.getItem('dashboard-cards-orderByField') as OrderByFieldTypes) || 'accountId'
    );
    const [orderByDirection, setOrderByDirection] = useState<OrderByDirectionTypes>(
        (localStorage.getItem('dashboard-cards-orderByDirection') as OrderByDirectionTypes) ||
            'desc'
    );

    useEffect(() => {
        localStorage.setItem('dashboard-cards-filter', filter);
        localStorage.setItem('dashboard-cards-orderByField', orderByField);
        localStorage.setItem('dashboard-cards-orderByDirection', orderByDirection);
    }, [filter, orderByField, orderByDirection]);

    const filteredAccounts = useMemo(() => {
        const filtered = accounts?.filter((account) => {
            const isDisconnected = isAccountDisconnected(account);

            if (filter === 'all' && !isDisconnected) return true;
            if (filter === 'active') return account.eaActive && !isDisconnected;
            if (filter === 'inactive') return !account.eaActive && !isDisconnected;
            if (filter === 'disconnected') return isDisconnected;
            return false;
        });

        if (orderByField === 'profit') {
            return orderBy(
                filtered,
                (account: MT5Account) => account.balance - account.startingBalance,
                [orderByDirection, 'desc']
            );
        }

        return orderBy(filtered, [orderByField], [orderByDirection]);
    }, [accounts, filter, orderByField, orderByDirection]);

    const handleFilterChange = (key: FilterTypes) => {
        setFilter(key);
    };

    return (
        <>
            <div
                style={{
                    display: 'flex',
                    backgroundColor: colorBgContainer,
                    padding: '16px 24px',
                    gap: 12,
                }}
            >
                <Select
                    style={{ width: 130 }}
                    value={filter}
                    onChange={(value: FilterTypes) => handleFilterChange(value)}
                >
                    <Select.Option value='all'>All</Select.Option>
                    <Select.Option value='active'>Active</Select.Option>
                    <Select.Option value='inactive'>Inactive</Select.Option>
                    <Select.Option value='disconnected'>Disconnected</Select.Option>
                </Select>
                <Select
                    value={orderByField}
                    onChange={(value: OrderByFieldTypes) => setOrderByField(value)}
                >
                    <Select.Option value='accountId'>Account ID</Select.Option>
                    <Select.Option value='balance'>Balance</Select.Option>
                    <Select.Option value='equity'>Equity</Select.Option>
                    <Select.Option value='profit'>Profit</Select.Option>
                </Select>
                <Select
                    value={orderByDirection}
                    onChange={(value: OrderByDirectionTypes) => setOrderByDirection(value)}
                >
                    <Select.Option value='asc'>Asc</Select.Option>
                    <Select.Option value='desc'>Desc</Select.Option>
                </Select>
            </div>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: 24,
                    padding: 24,
                    flexWrap: 'wrap',
                    overflow: 'auto',
                }}
            >
                {filteredAccounts?.map((account: MT5Account) => (
                    <Card
                        key={account.accountId}
                        style={{
                            height: 256,
                            width: 300,
                            opacity: !account.eaActive || isAccountDisconnected(account) ? 0.5 : 1,
                        }}
                    >
                        <Meta
                            avatar={<AccountCardStatusIcon account={account} />}
                            title={<AccountCardTitle account={account} />}
                            description={<AccountCardDescription account={account} />}
                        />
                        <AccountCardContent account={account} />
                    </Card>
                ))}
            </div>
        </>
    );
};

export default AccountsCards;

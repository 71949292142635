import endpoints from 'api/utils/endpoints';
import ChartConfig from 'types/charts/ChartConfig';

export const loadCharts = async () => {
    const response = await endpoints.charts.list.get();

    if (response.status !== 200) {
        throw new Error('Failed to load charts');
    }

    return response.data as ChartConfig[];
};

export default {
    loadCharts,
};

import { createAsyncThunk, createSlice, nanoid } from '@reduxjs/toolkit';
import { sub } from 'date-fns';
import { generate } from 'random-words';

import endpoints from 'api/utils/endpoints';
import StrategyDefinition from 'components/strategies/StrategyDefinition';
import StrategyListItemDto from 'components/strategies/StrategyListItemDto';
import { getStrategyDefinition } from 'repos/strategyRepo';
import { RootState } from 'store';

interface StrategyResponse {
    strategies: StrategyListItemDto[];
}

interface IStrategyState {
    loaded: boolean;
    strategyLoading: boolean;
    strategies: StrategyListItemDto[];
    favorites: string[];
    editingStrategy?: StrategyDefinition;
}

const initialState: IStrategyState = {
    loaded: false,
    strategyLoading: false,
    strategies: [],
    favorites: [],
};

export const createDefaultStrategy = () => {
    const name = generate({ wordsPerString: 2, exactly: 1, join: ' ' });
    const newStrategy: StrategyDefinition = {
        name: name.replace(/(^|\s)\S/g, (x) => x.toUpperCase()),
        description: '',
        id: nanoid(),
        settings: {
            balance: 100000,
            stopLoss: 0,
            takeProfit: 0,
            risk: 2,
            pyramiding: 1,
            directions: ['buy', 'sell'],
            spread: 2,
            startDate: sub(new Date(), { months: 1 }),
        },
        inputs: [], // TODO: Set a default input
        buy_conditions: [], // TODO: Set a default buy and sell condition
        sell_conditions: [],
        created_date: new Date(),
        created_by: '',
    };

    console.log(newStrategy.settings.startDate);

    return newStrategy;
};

export const loadEditingStrategy = createAsyncThunk('strategies/definition', getStrategyDefinition);

export const loadStrategies = createAsyncThunk('strategies/load', async () => {
    const response = await endpoints.strategies.list.get();

    if (response.status !== 200) {
        throw new Error('Failed to load strategies');
    }

    return response.data as StrategyResponse;
});

export const updateStrategyName = createAsyncThunk(
    'strategies/rename',
    async ({ id, name }: { id: string; name: string }) => {
        const response = await endpoints.strategies.rename.put({
            templateValues: {
                strategyId: id,
            },
            data: name,
        });

        if (response.status !== 200) {
            throw new Error('Failed to rename strategy');
        }

        return { id, name };
    }
);

export const deleteStrategy = createAsyncThunk('strategies/delete', async (id: string) => {
    const response = await endpoints.strategies.byId.delete({
        templateValues: {
            strategyId: id,
        },
    });

    if (response.status !== 200) {
        throw new Error('Failed to delete strategy');
    }

    return id;
});

export const createStrategy = createAsyncThunk(
    'strategies/create',
    async (strategy?: StrategyDefinition) => {
        const newStrategy = strategy || createDefaultStrategy();
        const response = await endpoints.strategies.save.post({
            data: newStrategy,
        });

        if (response.status !== 200) {
            throw new Error('Failed to create strategy');
        }

        return response.data as StrategyListItemDto;
    }
);

export const updateStrategy = createAsyncThunk(
    'strategies/update',
    async (strategy: StrategyDefinition) => {
        const response = await endpoints.strategies.save.post({
            data: strategy,
        });

        if (response.status !== 200) {
            throw new Error('Failed to update strategy');
        }

        return response.data as StrategyListItemDto;
    }
);

const strategySlice = createSlice({
    name: 'strategies',
    initialState,
    reducers: {
        addFavorite: (state, action) => {
            state.favorites.push(action.payload);
        },
        removeFavorite: (state, action) => {
            state.favorites = state.favorites.filter((f) => f !== action.payload);
        },
        clearEditingStrategy: (state) => {
            state.editingStrategy = undefined;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(loadStrategies.fulfilled, (state, action) => {
            state.strategies = action.payload.strategies;
            state.loaded = true;
        });

        builder.addCase(createStrategy.fulfilled, (state, action) => {
            state.strategies.push(action.payload);
        });

        builder.addCase(deleteStrategy.fulfilled, (state, action) => {
            state.strategies = state.strategies.filter((s) => s.id !== action.payload);
        });

        builder.addCase(updateStrategyName.fulfilled, (state, action) => {
            const { id, name } = action.payload;
            const strategy = state.strategies.find((s) => s.id === id);
            if (!strategy) return;
            strategy.name = name;
        });

        builder.addCase(updateStrategy.fulfilled, (state, action) => {
            const index = state.strategies.findIndex((s) => s.id === action.payload.id);
            state.strategies[index] = action.payload;
        });

        builder.addCase(loadEditingStrategy.pending, (state) => {
            state.strategyLoading = true;
        });
        builder.addCase(loadEditingStrategy.rejected, (state) => {
            state.strategyLoading = false;
        });
        builder.addCase(loadEditingStrategy.fulfilled, (state, action) => {
            state.strategyLoading = false;
            state.editingStrategy = action.payload;
        });
    },
});

export const { addFavorite, removeFavorite, clearEditingStrategy } = strategySlice.actions;

export const selectStrategies = (state: RootState) => state.strategies.strategies;
export const selectStrategiesLoaded = (state: RootState) => state.strategies.loaded;
export const selectFavorites = (state: RootState) => state.strategies.favorites;
export const selectEditingStrategy = (state: RootState) => state.strategies.editingStrategy;
export const selectStrategyLoading = (state: RootState) => state.strategies.strategyLoading;

export default strategySlice.reducer;

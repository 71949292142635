import { createSlice } from '@reduxjs/toolkit';

import { RootState } from './index';

export interface RenameEvent {
    text: string;
    entityName: string;
    onSave: (name: string) => void;
}

interface ThemeState {
    renameEvent: RenameEvent | null;
}

const initialState: ThemeState = {
    renameEvent: null,
};

export const utilitySlice = createSlice({
    name: 'utility',
    initialState,
    reducers: {
        setRenameEvent: (state, action) => {
            state.renameEvent = action.payload;
        },
    },
});

export const { setRenameEvent } = utilitySlice.actions;

export const selectRenameEvent = (state: RootState) => state.utility.renameEvent;
export default utilitySlice.reducer;

import { MoonOutlined, SunFilled } from '@ant-design/icons';
import { Button } from 'antd';

import { useAppDispatch, useAppSelector } from 'store';
import { enableDarkAlgorithm, enableLightAlgorithm, selectCurrentTheme } from 'store/themeSlice';
const ThemeSwitch = () => {
    const dispatch = useAppDispatch();
    const currentTheme = useAppSelector(selectCurrentTheme);

    const handleThemeChange = () => {
        const newTheme = currentTheme === 'light' ? 'dark' : 'light';
        if (newTheme === 'light') dispatch(enableLightAlgorithm());
        else dispatch(enableDarkAlgorithm());
    };

    return (
        <Button
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            icon={currentTheme === 'light' ? <MoonOutlined /> : <SunFilled />}
            onClick={handleThemeChange}
        />
    );
};

export default ThemeSwitch;

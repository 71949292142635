import { differenceInMinutes } from 'date-fns';

import { MT5Account } from 'types/MT5Account';

export type AccountStatus = 'active' | 'paused' | 'disconnected';

export const isAccountDisconnected = (account: MT5Account) => {
    const lastPingTime = account.lastPingTime ? new Date(account.lastPingTime) : new Date();
    const now = new Date();
    return differenceInMinutes(now, lastPingTime) > 5;
};

export const getAccountStatus = (account: MT5Account): AccountStatus => {
    if (isAccountDisconnected(account)) {
        return 'disconnected';
    } else if (account.eaActive) {
        return 'active';
    } else {
        return 'paused';
    }
};

import axios, { AxiosResponse, CancelToken } from 'axios';

import { ApiRequestParameters } from './apiClientUtils';
import ApiEndpointTemplate from './ApiEndpointTemplate';

export function setEnvironment(url: string, apiKey: string) {
    axios.defaults.baseURL = url;
    axios.defaults.headers.common['Authorization'] = `Bearer ${apiKey}`;
    axios.defaults.headers.common['Content-Type'] = 'application/json';
}

export async function doFetch(
    url: string,
    method: string,
    data: unknown,
    cancelToken?: CancelToken
): Promise<AxiosResponse> {
    const result = await axios.request({
        url,
        method,
        data,
        cancelToken,
    });

    return result;
}

export default class ApiEndpoint<T extends string> {
    template: ApiEndpointTemplate<T>;
    constructor(template: string) {
        this.template = new ApiEndpointTemplate(template);
    }

    buildUrl({ templateValues, queryParameters }: ApiRequestParameters<T> = {}): string {
        return `${this.template.build({ templateValues, queryParameters })}`;
    }

    get({
        templateValues,
        queryParameters,
        data,
        cancelToken,
    }: ApiRequestParameters<T> = {}): Promise<AxiosResponse> {
        const url = this.buildUrl({ templateValues, queryParameters });
        return doFetch(url, 'get', data, cancelToken);
    }

    post({
        templateValues,
        queryParameters,
        data,
        cancelToken,
    }: ApiRequestParameters<T> = {}): Promise<AxiosResponse> {
        const url = this.buildUrl({ templateValues, queryParameters });
        return doFetch(url, 'post', data, cancelToken);
    }

    put({
        templateValues,
        queryParameters,
        data,
        cancelToken,
    }: ApiRequestParameters<T> = {}): Promise<AxiosResponse> {
        const url = this.buildUrl({ templateValues, queryParameters });
        return doFetch(url, 'put', data, cancelToken);
    }

    delete({
        templateValues,
        queryParameters,
        data,
        cancelToken,
    }: ApiRequestParameters<T> = {}): Promise<AxiosResponse> {
        const url = this.buildUrl({ templateValues, queryParameters });
        return doFetch(url, 'delete', data, cancelToken);
    }
}
